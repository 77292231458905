<section class="c-subtitle" #subtitlePanel>
  <section class="c-subtitle__header">
    <div class="c-subtitle__header__text">
      <span>Legenda</span>
    </div>
  </section>
  <section class="c-subtitle__content">
    <div class="c-baselayer__list" *ngFor="let subtitle of subtitles">
      <div class="subtitle-box" [ngStyle]="{'border': '1px solid '+subtitle.color+''}">
        <div [ngStyle]="{'background-color': subtitle.color}"></div>
      </div>
      <div style="display: grid;"> 
        <span class="subtitle-text" style="padding-bottom: 5px;">{{subtitle.title}} </span>
        <span class="subtitle-source">{{subtitle.source}}</span>
      </div>
      
    </div>
  </section>
</section>
