<div class="c-controll-cetralized" (keyup.esc)="onKeyUp($event)">
  <!--
    Remover botão
    <div (click)="modalCampaign()" class="c-controll-map-btn">
      <span>C</span>
    </div>
  -->
  <div class="c-controll-map-btn" >
    <img
      class="icon-default"
      src="/assets/img/icon_lupa.svg"
      alt=""
      (click)="active()"
    />
  </div>
</div>
