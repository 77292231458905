import { Component, EventEmitter, Output } from '@angular/core';
import { onClickMarginTop } from 'src/app/pages/page-events';
import { OnSideNavToggle } from '../sidenav.component';

@Component({
  selector: 'app-sidenav-toggle-button',
  templateUrl: './sidenav-toggle-button.component.html',
  styleUrls: ['./sidenav-toggle-button.component.scss']
})
export class SidenavToggleButtonComponent {
  statusHeader = 'CLOSE';

  ngOnInit(): void {
    onClickMarginTop.subscribe((action) => {
      this.statusHeader = action;
    })
  }

  toggle() {
    OnSideNavToggle.emit();
  }

}
