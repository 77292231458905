<section class="c-add">
  <section class="c-add__header">
    <div class="c-add__header__text">
      <span *ngIf="!userFormData.id">Adicionar usuário</span>
      <span *ngIf="userFormData.id">Atualizar usuário</span>
    </div>
    <div class="c-add__header__close">
      <span (click)=onNoClick() mat-dialog-close>X</span>
    </div>
  </section>

  <form (ngSubmit)="onSubmit()">
    <section class="c-add__content">
      <p>
        <mat-form-field appearance="outline">
          <mat-label>Nome</mat-label>
          <input matInput placeholder="Digite o nome" type="" [(ngModel)]="userFormData.name"
            [ngModelOptions]="{standalone: true}">
        </mat-form-field>
      </p>
      <p>
        <mat-form-field appearance="outline">
          <mat-label>E-mail</mat-label>
          <input matInput placeholder="Digite o e-mail" [(ngModel)]="userFormData.email" type="email"
            [ngModelOptions]="{standalone: true}">
        </mat-form-field>
      </p>
      <p>
        <mat-form-field appearance="outline">
          <mat-label>Nível de acesso</mat-label>
          <mat-select [(ngModel)]="userFormData.security_level" [ngModelOptions]="{standalone: true}">
            <mat-option [value]="1">Registrado</mat-option>
            <mat-option [value]="2">Admin</mat-option>
          </mat-select>
        </mat-form-field>
      </p>

      <p>
        <mat-form-field appearance="outline">
          <mat-label>Senha</mat-label>
          <input matInput placeholder="Digite a senha" [(ngModel)]="userFormData.password" type="password"
            [ngModelOptions]="{standalone: true}">
        </mat-form-field>
      </p>
      <p>

      <p>
        <mat-form-field appearance="outline">
          <mat-label>Confirme a senha</mat-label>
          <input matInput placeholder="Confime a senha" [(ngModel)]="userFormData.cpassword" type="password"
            [ngModelOptions]="{standalone: true}">
        </mat-form-field>
        <mat-error *ngIf="userFormValidation['password'] === false">As senhas não são idênticas</mat-error>
      </p>
      <p>
        <mat-error *ngIf="serverResponseMsg !== null">{{serverResponseMsg}}</mat-error>
    </section>

    <section class="c-add__btn">
      <button mat-raised-button color="primary">
        <ng-container *ngIf="!userFormData.id"> Cadastrar usuário</ng-container>
        <ng-container *ngIf="userFormData.id"> Atualizar usuário</ng-container>
      </button>
    </section>
  </form>
</section>