import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { EmailComponent } from 'src/app/modules/users';
@Component({
  selector: 'app-user-layout',
  templateUrl: './user-layout.component.html',
  styleUrls: ['./user-layout.component.scss']
})
export class UserLayoutComponent implements OnInit {
  modalComponents: {}
  constructor(
    public dialog: MatDialog
  ) { }

  ngOnInit(): void {
    this.modalComponents = {
      'EmailComponent': EmailComponent
    }
  }



}
