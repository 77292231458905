<section class="c-baselayer" #baseLayerPanel>
  <section class="c-baselayer__header">
    <div class="c-baselayer__header__text">
      <span>Mapa base</span>
    </div>
  </section>
  <section class="c-baselayer__content">
    <div class="c-baselayer__list">
      <div class="colum-0">
        <form #form="ngForm">
          <mat-radio-group [(ngModel)]="baseLayerKey" name="baseLayerKey" (change)="layerSelected($event)">
            <mat-radio-button [value]="'Dark'">Escuro</mat-radio-button>
            <mat-radio-button [value]="'Satellite'">Satélite</mat-radio-button>
            <mat-radio-button [value]="'OSMStandard'">Open Street</mat-radio-button>
            <mat-radio-button [value]="'Roads'">Google Street</mat-radio-button>
            <mat-radio-button [value]="'MapBiomasClassification'">MapBiomas (2020)</mat-radio-button>
            <mat-radio-button [value]="'Planet'">Mosaico Planet (2021)</mat-radio-button>
            <!-- <mat-radio-button [value]="'SRTM'">SRTM</mat-radio-button> -->
          </mat-radio-group>
        </form>
      </div>
    </div>
  </section>
</section>
