import { Component, HostListener, Input, OnInit, SimpleChanges } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import * as L from 'leaflet';
import { SearchLocalizationDialogComponent } from './search-localization-dialog.component';

@Component({
  selector: 'app-search-localization',
  templateUrl: './search-localization.component.html',
  styleUrls: ['../btn-controll/btn-controll.component.scss']
})
export class SearchLocalizationComponent implements OnInit {
  @Input()
  map: L.Map;

  isActivated = false;

  markerUnique = L.featureGroup();

  constructor(public dialog: MatDialog) { }

  ngOnInit() {
    const savedCoords = sessionStorage.getItem('app-search-localization');
    if (savedCoords) {
      this.plotCoords(savedCoords);
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['map']) {
      this.map.addLayer(this.markerUnique);
    }
  }

  active() {
    this.isActivated = !this.isActivated;
    this.openDialog();
  }

  modalCampaign() {
  }

  openDialog(): void {
    const dialogRef = this.dialog.open(
      SearchLocalizationDialogComponent,
      {
        width: `360px`,
        position: {
          bottom: '64px',
          left: '10px',
        },
      }
    );

    dialogRef.componentInstance.change.subscribe((coordinates) => {
      if (coordinates !== null) {
        if (coordinates.lat.toString().search(/[,]/g) !== -1)
          coordinates.lat = Number(coordinates.lat.toString().replace(',', '.'));

        if (coordinates.long.toString().search(/[,]/g) !== -1)
          coordinates.long = Number(
            coordinates.long.toString().replace(',', '.')
          );

        this.markerUnique.addLayer(L.marker([coordinates.lat, coordinates.long]));

        if (!this.map.hasLayer(this.markerUnique)) {
          this.map
            .addLayer(this.markerUnique)
            .setView([coordinates.lat, coordinates.long], 10);

        } else {
          this.removeLayer()
        }
      } else
        this.removeLayer()
    });

    dialogRef.componentInstance.coordsChange.subscribe((coords) => {
      this.plotCoords(coords);
    });
  }

  onKeyUp($event: any) {
    if ($event.key == 'Escape') {
      if (this.map.hasLayer(this.markerUnique))
        this.removeLayer()
    }
  }

  removeLayer() {
    if (this.map.hasLayer(this.markerUnique)) {
      this.map.removeLayer(this.markerUnique);
      this.markerUnique.clearLayers();
    }
  }

  plotCoords(coords: string) {
    if (coords == null) {
      console.log(coords);
    }
    coords = coords.replace(/\s*,\s*/g, ",");
    const coordsList = coords.split(')')
      .map(c => c.replace(',(', '').replace('(', ''))
      .filter(c => c != '')
      .map(c => c.split(','))
      .filter(c => c.length == 2)
      .filter(c => c[0] != '' && c[1] != '');

    this.markerUnique.clearLayers();
    coordsList.forEach(c => {
      let icon = L.icon({
        iconUrl: 'assets/img/pin-marker.svg',
        iconSize: [40, 40],
        className: 'img-marker-icon'
      })
      this.markerUnique.addLayer(L.marker([parseFloat(c[0]), parseFloat(c[1])], { icon: icon }));
      this.markerUnique.on("click", (event: any) => {
        if(this.map.hasLayer(event.layer))
          this.markerUnique.removeLayer(event.layer)

        if(this.markerUnique.getLayers().length == 0)
          sessionStorage.removeItem('app-search-localization');
      });
    });
  }

  @HostListener('window:keyup.Esc', ['$event'])
  async clear(e: KeyboardEvent) {
    this.markerUnique.clearLayers();
    sessionStorage.removeItem('app-search-localization');
  }
}
