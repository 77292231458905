import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';
import { onHeaderFilterChange } from 'src/app/shared/components/header/header.events';
import { configGraph } from './../contracts';
import * as Highcharts from 'highcharts';
import { Options, SeriesColumnOptions } from 'highcharts';

@Component({
  selector: 'app-chart3',
  template: `
    <section class="c-chart-title"><h3>Cicatriz de Queimada: Agrupamento por tamanho</h3></section>
    <div #chart></div>
    <mat-radio-group [(ngModel)]="typeUnit">
      <mat-radio-button [value]="'und'" (click)="changeUnityGraph('und')">Alertas</mat-radio-button>
      <mat-radio-button [value]="'ha'" checked (click)="changeUnityGraph('ha')">Hectares</mat-radio-button>
    </mat-radio-group>
    <div class="c-chart-divider"></div>
  `,
  styleUrls: ['./../chart.module.scss']
})
export class Chart3Component implements OnInit {

  @ViewChild('chart', { static: true })
  chartElem: ElementRef;

  @Input()
  name;

  @Input()
  data: {
    date: Date;
    value: string;
  }[];

  dataGraph: any[];
  typeUnit: string = 'und';
  chart: any;
  filter: any = {};

  ranges = [
    '<1ha',
    '>1 & <5',
    '>5 & <10',
    '>10',
  ]

  constructor(
    private apiService: ApiService
  ) { }

  ngOnInit(): void {

    onHeaderFilterChange
      .subscribe(filter => {
        this.filter = Object.assign(this.filter, filter);
        this.updateData();
      });
  }

  async updateData() {

    // const query: any = {};

    // if (this.filter.dateStart) {
    //   query.dateStart = this.filter.dateStart.toISOString().substr(0, 10);
    //   query.dateEnd = this.filter.dateEnd.toISOString().substr(0, 10);
    // }

    // if (this.filter.ti?.id) {
    //   query.idTI = this.filter.ti.id;
    // }

    // if (this.filter.buffer) {
    //   query.buffer = this.filter.buffer;
    // }

    // if (this.filter.vector?.id) {
    //   query.categoria = this.filter.vector.categoria;
    //   query.idCategoria = this.filter.vector.id;
    // }

    // if (this.filter.class) {
    //   query.classe = this.filter.class;
    // }

    // if (this.filter.source) {
    //   query.source = this.filter.source;
    // }

    // if (this.filter.embargoed) {
    //   query.embargoed = this.filter.embargoed;
    // }

    // if (this.filter.authorized) {
    //   query.authorized = this.filter.authorized;
    // }

    // if (this.filter.validation) {
    //   query.validation = this.filter.validation;
    // }

    // if (this.filter.size) {
    //   query.size = this.filter.size;
    // }

    // if (this.filter.satellite) {
    //   query.satellite = this.filter.satellite;
    // }

    const filterData = this.filter;

    const chartQuery: any = {
      ...(filterData.ti && filterData.ti.length > 0 ? { ti_list: filterData.ti.map(ti => ti.id) } : {}),
      ...(filterData.class && filterData.class.length > 0 ? { class_list: filterData.class } : {}),
      ...(filterData.vector && filterData.vector.length > 0 ? { territorial_category: this.updateVectorLayers(filterData.vector) } : {}),
      ...(filterData.source && filterData.source.length > 0 ? {source_list: filterData.source}: {}),
      ...(filterData.size && filterData.size.length > 0 ? {size: filterData.size}: {}),
      ...(filterData.buffer ? { buffer: filterData.buffer } : {}),
      ...(filterData.satellite ? { satellite: filterData.satellite } : {}),
      ...(filterData.authorized ? {authorized: filterData.authorized}: {}),
      ...(filterData.embargoed ? {embargoed: filterData.embargoed}: {}),
      ...(filterData.validation ? {validation: filterData.validation}: {}),
      ...(filterData.dateStart ? {dateStart: filterData.dateStart}: {}),
      ...(filterData.dateEnd ? {dateEnd: filterData.dateEnd}: {})
    };

    let data = await this.apiService.getChartDataBySizes(chartQuery).toPromise();

    data = data.filter(item => item.type == "Cicatriz de Queimada");

    data = this.ranges.map((range => {
      return data.filter(item => item.range == range).reduce((accum, item) => {
        accum.count = accum.count + parseInt(item.count);
        accum.sum = accum.sum + new Number(item.sum);
        return accum;
       },
        {sum: 0, count: 0, range: range })
    }));

    this.dataGraph = data.filter(item => item.count != 0);

    this.setData({data: this.dataGraph, typeUnit: 'und'});
  }

  changeUnityGraph(typeUnit: string) {
    this.typeUnit = typeUnit;
    this.setData({data: this.dataGraph, typeUnit});
  }

  setData(config: configGraph): void {
    let field = config.typeUnit === 'ha' ? `sum` : `count`;
    const serieData = config.data.map(item => [item.range, +parseFloat(item[field]).toFixed(0)]);

    const columnSeriesOptions: SeriesColumnOptions = {
      type: 'column',
      name: config.typeUnit === 'ha' ? `Total de hectares` : `Número de alertas`,
      data: serieData,
      color: 'magenta'
    };

    const options: Options = {
      exporting: {
        buttons: {
          contextButton: {
            menuItems: ['downloadPNG', 'downloadCSV', 'viewFullscreen']
          }
        }
      },
      chart: {
        type: 'column'
      },
      title: {
        text: ''
      },
      xAxis: {
        type: 'category',
        title: {
          text: 'Tamanho'
        },
        labels: {
          rotation: -45,
          style: {
            fontSize: '13px',
            fontFamily: 'Verdana, sans-serif'
          }
        }
      },
      yAxis: {
        min: 0,
        title: {
          text: config.typeUnit === 'ha' ? `Total de hectares` : `Número de alertas`
        }
      },
      legend: {
        enabled: false
      },
      plotOptions: {
        series: {
          dataLabels: {
            enabled: true,
            formatter: function() {
              return this.y;
            }
          }
        }
      },
      series: [columnSeriesOptions]
    };

    if (!this.chart) {
      this.chart = Highcharts.chart(this.chartElem.nativeElement, options);
    } else {
      this.chart.update(options);
    }
  }

  updateVectorLayers(vectorData) {
    if (vectorData && vectorData.length > 0) {
      vectorData = vectorData.reduce((acc, item) => {
        if (!acc[item.categoria]) {
          acc[item.categoria] = [];
        }
        acc[item.categoria].push(item.id);
        return acc;
      }, {});
    }
    return vectorData;
  }
}
