<section class="c-password">
  <section class="c-password__header">
    <div class="c-password__header__text">
      <span>Alterar senha</span>
    </div>
    <div class="c-password__header__close">
      <span (click)=onNoClick() mat-dialog-close>X</span>
    </div>
  </section>

  <section class="c-password__content">
    <p>
      Preencha os campos abaixo para alterar sua senha.
    </p>
    <form [formGroup]="changePasswordFormGroup" (ngSubmit)="changePassword()">
      <p>
        <mat-form-field appearance="outline">
          <mat-label>Senha atual</mat-label>
          <input type="password" formControlName="old_password" [errorStateMatcher]="matcher" required matInput
            placeholder="Informe seu e-mail" [(ngModel)]="changePasswordData.old_password" matInput
            placeholder="Digite sua senha atual">
        </mat-form-field>
      </p>
      <p>
        <mat-form-field appearance="outline">
          <mat-label>Nova senha</mat-label>
          <input type="password" formControlName="password" [errorStateMatcher]="matcher" required matInput
            placeholder="Informe seu e-mail" [(ngModel)]="changePasswordData.password" matInput
            placeholder="Digite a nova senha">
        </mat-form-field>
      </p>
      <p>
        <mat-form-field appearance="outline">
          <mat-label>Confirme a nova senha</mat-label>
          <input type="password"
            formControlName="confirm_password" 
            [errorStateMatcher]="matcher" required matInput
             [(ngModel)]="changePasswordData.confirm_password"
            placeholder="Confirme a nova senha">
        </mat-form-field>
      </p>
      <p style="color: red;" *ngIf="validEqualPasswordConfirm && changePasswordData.password  !== changePasswordData.confirm_password">
          Ops! Erro ao confirmar senha
      </p>
      <p style="color: red;" *ngIf="errorRequest">
         Usuário ou Senha inválidos
      </p>
      <section class="c-password__btn">
        <button mat-raised-button color="primary">Confirmar alteração</button>
      </section>
    </form>
  </section>
</section>
