import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { RecoverPasswordComponent } from 'src/app/modules/users/recoverpassword/recoverpassword.component';
import { AuthService, LoginData } from 'src/app/services/auth.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  loginData: LoginData = {} as LoginData;

  modalComponents: {};

  showLoginError = false;

  constructor(
    private authService: AuthService,
    private router: Router,
    public dialog: MatDialog
  ) { }

  ngOnInit(): void {
    this.modalComponents = {
      'RecoverPasswordComponent': RecoverPasswordComponent
    }
  }

  async onSubmit() {
    await this.authService.login(this.loginData).catch(() => {
      this.showLoginError = true;
      setTimeout(() => {
        this.showLoginError = false;
      }, 3000);
    });
    this.router.navigate(['/home']);
  }

  openDialog(component, width): void {
    this.dialog.open(this.modalComponents[component], {
      width: `${width}px`
    });
  }
}
