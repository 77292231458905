import { Component, OnInit } from '@angular/core';
import { Feature } from 'geojson';
import { ApiService } from 'src/app/services/api.service';

@Component({
  selector: 'app-popup-content',
  templateUrl: './popup-content.component.html',
  styleUrls: ['./popup-content.component.scss']
})
export class PopupContentComponent implements OnInit {

  territoryId: number;

  territoryCategory: string;

  territoryName: number;

  data: {
    feature: Feature,
    category: string
  }

  constructor(
    public apiService: ApiService
  ) { }

  async ngOnInit() { }

  setData(data: any) {
    this.data = data;
  }
}
