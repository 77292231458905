<div class="c-controll-map">
  <ng-content select="app-search-localization"></ng-content>
  <!-- <div class="c-controll-cetralized">
    <div class="c-controll-map-btn">
      <img class="icon-default" src="../../../../assets/img/icon_lupa.svg" alt="" (click)="openDialog('SearchLocalizationComponent', 360, true)" >
    </div>
  </div> -->

  <div class="c-controll-zoom">
    <button class="c-controll-map-btn c-controll-map-btn-zoom" [ngStyle]="activeOpenSubtitle ? {'background': '#a9c79b'}: ''" (click)="toogleSubtitle()">
      <img class="icon-default" src="../../../../assets/img/legend_icon.svg"  alt="">
    </button>
    <button class="c-controll-map-btn c-controll-map-btn-zoom" [ngStyle]="activeOpenDialogOptionsOverLayer ? {'background': '#a9c79b'}: ''" (click)="openDialogOptionsLayer('over')" >
      <img class="icon-default" src="../../../../assets/img/layers_.svg"  alt="">
    </button>
    <button class="c-controll-map-btn c-controll-map-btn-zoom" [ngStyle]="activeOpenDialogOptionsBaseLayer ? {'background': '#a9c79b'}: ''" (click)="openDialogOptionsLayer('base')">
      <img class="icon-default" src="../../../../assets/img/map.svg" alt="">
    </button>
  </div>
  <div class="c-controll-date">
    <form #dateFilter>
      <!-- <mat-form-field color="accent" appearance="fill"> -->
      <mat-form-field color="accent" appearance="fill">
        <mat-label>Data início</mat-label>
        <input
            matInput
            [matDatepicker]="picker1"
            disabled [value]="date.value"
            (dateChange)="dateChange()"
            [(ngModel)]="filter.dateStart"
            name="dateStart"
        >
        <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
        <mat-datepicker #picker1 disabled="false" color="primary" ></mat-datepicker>
      </mat-form-field>

      <mat-form-field color="accent" appearance="fill">
        <mat-label>Data fim</mat-label>
        <input matInput [matDatepicker]="picker2" disabled [value]="date.value" (dateChange)="dateChange()" [(ngModel)]="filter.dateEnd" name="dateEnd">
        <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
        <mat-datepicker #picker2 disabled="false"></mat-datepicker>
      </mat-form-field>
    </form>
  </div>
</div>

<app-baselayer  [statusPanel]="activeOpenDialogOptionsBaseLayer" (openLayerEvent)="openDialogOptionsLayer($event)" [ngStyle]="activeOpenDialogOptionsBaseLayer ? {'display': 'block'}: {'display': 'none'}"></app-baselayer>

<app-overlayer  [statusPanel]="activeOpenDialogOptionsOverLayer" (openLayerEvent)="openDialogOptionsLayer($event)" [ngStyle]="activeOpenDialogOptionsOverLayer? {'display': 'block'}: {'display': 'none'}"></app-overlayer>

<app-subtitle   [statusPanel]="activeOpenSubtitle" (openLayerEvent)="toogleSubtitle()" [ngStyle]="activeOpenSubtitle? {'display': 'block'}: {'display': 'none'}"></app-subtitle>
