import { map } from 'rxjs/operators';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatAccordion } from '@angular/material/expansion';
import { FormBuilder, FormGroup } from '@angular/forms';
import { onHeaderFilterChange } from 'src/app/shared/components/header/header.events';
import { onMapFilterDateChange } from '../map.events';
import { ApiService } from 'src/app/services/api.service';
import { onClickMarginTop } from 'src/app/pages/page-events';
import { onStatisticEvent } from './statistic.events';
import { VectorLayer } from 'src/app/services/entities/vector-layer';
@Component({
  selector: 'app-statistic',
  templateUrl: './statistic.component.html',
  styleUrls: ['./statistic.component.scss']
})
export class StatisticComponent implements OnInit {

  @ViewChild(MatAccordion) accordion: MatAccordion;
  data: any[];
  panelOpenState = false;
  toppings: FormGroup;
  checked = true;
  totalAlerts = 0;
  totalDeforestedArea = 0;
  filter: any = {};
  statusHeader = 'CLOSE';
  constructor(
    fb: FormBuilder,
    private apiService: ApiService
  ) {
    this.toppings = fb.group({
      pepperoni: false,
      extracheese: false,
      mushroom: false
    });
  }
  ngOnInit(): void {

    onHeaderFilterChange
      .subscribe(filter => {
        this.filter = Object.assign(this.filter, filter);
        this.updateData();
      });

    onClickMarginTop.subscribe((action) => {
      this.statusHeader = action;
    })


  }

  panelExpansionStyle = 'mat-expansion-panel-false';

  panelExpansionClosed() {
    this.panelExpansionStyle = 'mat-expansion-panel-false'
    onStatisticEvent.emit({ expansion: false });
  }

  panelExpansionOpened() {
    this.panelExpansionStyle = 'mat-expansion-panel-true'
    onStatisticEvent.emit({ expansion: true });
  }

  async updateData() {

    // const query: any = {};

    // if (this.filter.dateStart) {
    //   query.dateStart = this.filter.dateStart.toISOString().substr(0, 10);
    //   query.dateEnd = this.filter.dateEnd.toISOString().substr(0, 10);
    // }

    // if (this.filter.ti?.id) {
    //   query.idTI = this.filter.ti.id;
    // }

    // if (this.filter.buffer) {
    //   query.buffer = this.filter.buffer;
    // }

    // if (this.filter.class) {
    //   query.classe = this.filter.class;
    // }

    // if (this.filter.vector?.id) {
    //   query.categoria = this.filter.vector.categoria;
    //   query.idCategoria = this.filter.vector.id;
    // }

    // if (this.filter.source) {
    //   query.source = this.filter.source;
    // }

    // if (this.filter.embargoed) {
    //   query.embargoed = this.filter.embargoed;
    // }

    // if (this.filter.authorized) {
    //   query.authorized = this.filter.authorized;
    // }

    // if (this.filter.validation) {
    //   query.validation = this.filter.validation;
    // }

    // if (this.filter.size) {
    //   query.size = this.filter.size;
    // }

    // if (this.filter.satellite) {
    //   query.satellite = this.filter.satellite;
    // }

    const filterData = this.filter;

    const chartQuery: any = {
      ...(filterData.ti && filterData.ti.length > 0 ? { ti_list: filterData.ti.map(ti => ti.id) } : {}),
      ...(filterData.class && filterData.class.length > 0 ? { class_list: filterData.class } : {}),
      ...(filterData.vector && filterData.vector.length > 0 ? { territorial_category: this.updateVectorLayers(filterData.vector) } : {}),
      ...(filterData.source && filterData.source.length > 0 ? {source_list: filterData.source}: {}),
      ...(filterData.size && filterData.size.length > 0 ? {size: filterData.size}: {}),
      ...(filterData.buffer ? { buffer: filterData.buffer } : {}),
      ...(filterData.satellite ? { satellite: filterData.satellite } : {}),
      ...(filterData.authorized ? {authorized: filterData.authorized}: {}),
      ...(filterData.embargoed ? {embargoed: filterData.embargoed}: {}),
      ...(filterData.validation ? {validation: filterData.validation}: {}),
      ...(filterData.dateStart ? {dateStart: filterData.dateStart}: {}),
      ...(filterData.dateEnd ? {dateEnd: filterData.dateEnd}: {})
    };

    this.data = await this.apiService.getChartDataClasses(chartQuery).toPromise();

    this.totalAlerts = this.sumDataStatistic(this.data, 'count');
    this.totalDeforestedArea = this.sumDataStatistic(this.data, 'sum');
  }

  updateVectorLayers(vectorData) {
    if (vectorData && vectorData.length > 0) {
      vectorData = vectorData.reduce((acc, item) => {
        if (!acc[item.categoria]) {
          acc[item.categoria] = [];
        }
        acc[item.categoria].push(item.id);
        return acc;
      }, {});
    }
    return vectorData;
  }

  sumDataStatistic(data: any[], typeField: string) {
    return data.reduce((acc, current) => {
      return acc + parseInt(current[typeField])
    }, 0);
  }
}
