import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpResponse, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { tap } from 'rxjs/operators';
import { LoadingService } from './../../services/loading.service';

@Injectable()
export class LoadingHttpInterceptor implements HttpInterceptor {
    constructor(private loadingService: LoadingService) { }
    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
      this.loadingService.isLoading.next(true);
        return next
            .handle(req)
            .pipe(
                tap((event: HttpEvent<any>) => {
                    if (event instanceof HttpResponse) {
                        this.loadingService.isLoading.next(false);
                    }
                }, (error) => {
                  this.loadingService.isLoading.next(false);
                })
            );
    }
}
