<section class="c-email">
  <section class="c-email__header">
    <div class="c-email__header__text">
      <span>Alterar e-mail</span>
    </div>
    <div class="c-email__header__close">
      <span (click)=onNoClick() mat-dialog-close>X</span>
    </div>
  </section>

  <form (ngSubmit)="updateMail()">
    <section class="c-email__content">
      <p>
        Preencha o campo abaixo com seu novo e-mail.
      </p>
      <p>
        <mat-form-field appearance="outline">
          <mat-label>E-mail</mat-label>
          <input type="email" [formControl]="emailFormControl" [errorStateMatcher]="matcher" required matInput
            placeholder="Informe seu e-mail" [(ngModel)]="mailData.email">
          <mat-error *ngIf="emailFormControl.hasError('email') && !emailFormControl.hasError('required')">
            Por favor entre com email válido
          </mat-error>
          <mat-error *ngIf="emailFormControl.hasError('required')">
            Email é <strong>obrigatório</strong>
          </mat-error>

        </mat-form-field>
        <strong style="color: red;" *ngIf="hasErrorUpdateEmail">
          Ops! Ocorreu algum erro ao atualizar email
        </strong>
      </p>
    </section>

    <section class="c-email__btn">
      <button [disabled]="emailFormControl.invalid" mat-raised-button color="primary">Confirmar
        alteração</button>
    </section>
  </form>
</section>