import { Injectable, EventEmitter } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';
import { Authorization } from './authorization';

import { URL_API } from 'src/environments/environment';
import { User } from './entities/user.entity';
import { resolve } from 'dns';


export class AuthEvents {
  static onLogin = new EventEmitter<Authorization>();
  static onUnauthorized = new EventEmitter();
  static onLogout = new EventEmitter();
}

@Injectable()
export class AuthService {

  /**
   * Auth Events
   *
   * @static
   * @memberof AuthService
   */
  static Events = {
    Login: new EventEmitter<Authorization>(),
    Unauthorized: new EventEmitter(),
    Logout: new EventEmitter()
  };


  constructor(
    private http: HttpClient
  ) { }

  /**
   * Auth Login
   *    
   * @param {LoginData} [user=null]
   * @returns {Observable<Object>}
   * @memberof AuthService
   */
  async login(user: LoginData): Promise<Authorization> {
    const authorization = await this.http.post<any>(URL_API + '/login', user).toPromise();

    localStorage.setItem('authorization', JSON.stringify(authorization));

    AuthService.Events.Login.emit(authorization);

    return authorization;

  }


  logout() {
    AuthService.Events.Logout.emit();
    localStorage.removeItem('authorization');
  }



  getAuthorization(): Authorization {
    const authorizationStorage = localStorage.getItem('authorization');

    let authorization = null;
    if (authorizationStorage) {
      authorization = Object.assign(new Authorization(), JSON.parse(authorizationStorage));
    }
    return authorization;
  }

}
export interface LoginData {

  /**
   * User username 
   *
   * @type {string}
   * @memberof LoginData
   */
  email: string;

  /**
   * User password
   *
   * @type {string}
   * @memberof LoginData
   */
  password: string;
}