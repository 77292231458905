<section class="c-page-list" id="">
  <section class="c-page-list-header">
    <div [routerLink]="['/home']">
      <mat-icon>keyboard_backspace</mat-icon> <span>Voltar</span>
    </div>
    <button (click)="openDialog('AddComponent', 450)" mat-raised-button class="c-btn-add" type="button">
      Adicionar usuário
      <mat-icon>add</mat-icon>
    </button>
  </section>
  <section class="c-page-list-body">
    <span>Lista de Usuários</span>
  </section>

  <section class="c-page-list-table">

    <!-- <table mat-table [dataSource]="users"> -->
    <table mat-table [dataSource]="dataSource" matSort>
      <ng-container matColumnDef="id">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> ID </th>
        <td mat-cell *matCellDef="let user"> {{user.id}} </td>
      </ng-container>

      <!-- Name Column -->
      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Name </th>
        <td mat-cell *matCellDef="let user"> {{user.name}} </td>
      </ng-container>

      <!-- Weight Column -->
      <ng-container matColumnDef="email">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Email </th>
        <td mat-cell *matCellDef="let user"> {{user.email}} </td>
      </ng-container>
     
      <!-- Weight Column -->
      <ng-container matColumnDef="security_level">
        <th mat-header-cell *matHeaderCellDef> Nível de acesso </th>
        <td mat-cell *matCellDef="let user">{{user.security_level == 1 ? "Registrado":"Administrador"}} </td>
      </ng-container>

      <!-- Symbol Column -->
      <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef> Ações </th>
        <td mat-cell *matCellDef="let user">
          <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
            <mat-icon>more_vert</mat-icon>
          </button>
          <mat-menu #menu="matMenu">
            <button (click)="edit(user)" mat-menu-item >
              <mat-icon>edit</mat-icon>
              <span >Editar</span>
            </button>
            <button (click)="remove(user)" mat-menu-item>
              <mat-icon>remove</mat-icon>
              <span >Remover</span>
            </button>
          </mat-menu>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns  sticky: true"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>

    <mat-paginator class="mat-paginator-sticky" [pageSizeOptions]="[5, 10, 20]"
    showFirstLastButtons
    [length]="100" 
    [pageSize]="10"  
    aria-label="Select page of periodic elements">
  </mat-paginator>
  </section>

</section>
