<section class="c-search-localization">
  <form (ngSubmit)="onCoordinatesChange($event)" (keyup.esc)="onKeyUp($event)">
    <section class="c-search-localization__content">
      <p style="width: 270px;">
        <mat-form-field appearance="outline" style="width: 100%;">
          <mat-label>Coordenadas (lat,long)</mat-label>
          <input matInput placeholder="lat,long" [(ngModel)]="coords"
            placeholder="Ex: (-11.226898,-63.643799),(-11.226898,-63.643799), ..." [ngModelOptions]="{standalone: true}">
        </mat-form-field>
      </p>
      <!-- <p>
        <mat-form-field appearance="outline">
          <mat-label>Latitude</mat-label>
          <input matInput placeholder="lat" [(ngModel)]="coordinates.lat" placeholder="Ex: -11.226898"
            [ngModelOptions]="{standalone: true}">
        </mat-form-field>
      </p>
      <p>
        <mat-form-field appearance="outline">
          <mat-label>Longitude</mat-label>
          <input matInput placeholder="long" [(ngModel)]="coordinates.long" placeholder="Ex: -63.643799"
            [ngModelOptions]="{standalone: true}">
        </mat-form-field>
      </p> -->
      <button style="border: 0; cursor: pointer; background: transparent;">
        <img style="color: black;" src="/assets/img/icon_lupa.svg" />
      </button>
    </section>
  </form>
</section>
