import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatAccordion } from '@angular/material/expansion';
import { AboutComponent } from './../about/about.component';
import { ShareComponent } from './../share/share.component';
import { DeforestationMaskComponent } from './../deforestation-mask/deforestation-mask.component';
import { AlertIdMessageComponent } from '../alert-id-message/alert-id-message.component';
import { onHeaderFilterChange } from './header.events';
import { EmailComponent, PasswordComponent } from './../../../modules/users'
import { ApiService } from 'src/app/services/api.service';
import { TI } from 'src/app/services/entities/ti';
import { LoadingService } from 'src/app/services/loading.service';
import { onClickMarginTop } from 'src/app/pages/page-events';
import { URL_VALIDATION_MODULE } from 'src/environments/environment';
import { Authorization } from 'src/app/services/authorization';
import { AuthService } from 'src/app/services/auth.service';
import { ActivatedRoute, Router } from '@angular/router';
import { onMapFilterDateChange } from 'src/app/modules/map/map.events';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { features } from 'process';
import { CustomStepDefinition, Options } from '@angular-slider/ngx-slider';
import { VectorLayer } from 'src/app/services/entities/vector-layer';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  @ViewChild(MatAccordion) accordion: MatAccordion;
  modalComponents: {};
  alertId: number;
  formFilter: FormGroup;
  featuresTi: TI[];
  alertClasses: string[];
  vectorLayers: VectorLayer[] = [];
  cities: VectorLayer[] = [];
  alertSources: string[];
  selectedCar: number;
  alertSatellite: string[];
  alertSizes: string[];
  appModule: any;
  panelExpansionStyle = 'mat-expansion-panel-false';
  panelOpenState = false;
  isClearing = false;

  ALERT_AREA_MAX = 1000000

  sizeRangeSliderOpts: Options = {
    stepsArray: [0, 5, 10, this.ALERT_AREA_MAX].map((value: any): CustomStepDefinition => {
      return { value: value, legend: value === this.ALERT_AREA_MAX ? 'MAX' : value.toString() };
    }),
    showTicksValues: true,
    showTicks: false
  };

  sizeRangeSliderMinValue: number = 0;
  sizeRangeSliderMaxValue: number = this.ALERT_AREA_MAX;

  initialFormValues = {
    ti: [],
    buffer: 0,
    class: [],
    vector: [],
    city: [],
    source: [],
    satellite: '',
    embargoed: '',
    authorized: '',
    validation: '',
    size: '',
    alertId: '',
    dateStart: new Date('2021-08-01'),
    dateEnd: new Date(),
  };

  appModules: Record <string, any> = {
    "MODULE_VISUALIZATION": {
      logo: "/assets/img/icon-menu/iconMenu_colorHovering/consulta_colorHovering.svg",
      title: "Módulo de Consulta e Visualização"
    },
    "MODULE_VALIDATION": {
      logo: "/assets/img/icon-menu/iconMenu_colorHovering/validacao_colorHovering.svg",
      title: "Módulo de Edição e Validação",
    },
    "MODULE_UPLOAD": {
      logo: "/assets/img/icon-menu/iconMenu_colorHovering/upload_colorHovering.svg",
      title: "Módulo de Upload",
    },
    "MODULE_DASHBOARD": {
      logo: "/assets/img/icon-menu/iconMenu_colorHovering/dashboard_colorHovering.svg",
      title: "Módulo de Dashboard",
    },
  };

  URL_VALIDATION_MODULE = URL_VALIDATION_MODULE;

  authorization: Authorization;

  constructor(
    public dialog: MatDialog,
    public router: Router,
    public apiService: ApiService,
    public authService: AuthService,
    public loadingService: LoadingService,
    private formBuilder: FormBuilder,
    private activatedRoute: ActivatedRoute,
  ) {

    this.authorization = this.authService.getAuthorization();

    this.modalComponents = {
      'AboutComponent': AboutComponent,
      'ShareComponent': ShareComponent,
      'EmailComponent': EmailComponent,
      'PasswordComponent': PasswordComponent,
      'DeforestationMaskComponent': DeforestationMaskComponent,
      'AlertIdMessageComponent': AlertIdMessageComponent
    }
  }

  async ngOnInit() {

    this.formFilter = this.formBuilder.group(this.initialFormValues);

    this.formFilter.setValue(this.initialFormValues);

    onMapFilterDateChange
    .subscribe(filter => {
      this.formFilter.patchValue({ dateStart: filter.dateStart });
      this.formFilter.patchValue({ dateEnd: filter.dateEnd });
      this.filterChange();
    });

    localStorage.removeItem('panelExpansion') //TODO adicionar um service
    this.panelExpansionClosed();

    this.appModule = this.appModules["MODULE_ALERTS_VALIDATION"];
    this.filterChange();
  }

  openDialog(component, width, message?): void {
    this.dialog.open(this.modalComponents[component], {
      width: `${width}px`,
      data: { message: message }
    });
  }

  async filterChange(event = null) {
    const formData = { ...this.formFilter.value };
    Object.keys(formData).forEach(key => {
      if (!formData[key]) {
        formData[key] = this.initialFormValues[key]
      }
    });

    formData.vector = [...this.formFilter.value.vector, ...this.formFilter.value.city];
    formData.size = this.updateSizeRangeOptions(formData.size)

    this.alertId = formData.alertId;

    // const [min, max] = formData.size;
    // const selectedOptions: string[] = [];

    // if (min < 5 && max >= 0) {
    //   selectedOptions.push('0-5');
    // }
    // if (min < 10 && max >= 5) {
    //   selectedOptions.push('5-10');
    // }
    // if (max > 10) {
    //   selectedOptions.push('10+');
    // }
    // formData.size = selectedOptions;

    let featuresTiQuery = {
      ...(formData.class && formData.class.length > 0 ? { class_list: formData.class } : {}),
      ...(formData.vector && formData.vector.length > 0 ? { territorial_category: this.updateVectorLayers(formData.vector) } : {}),
      ...(formData.source && formData.source.length > 0 ? { source_list: formData.source } : {})
    };

    let alertClassesQuery = {
      ...(formData.ti && formData.ti.length > 0 ? { ti_list: formData.ti.map(ti => ti.id) } : {}),
      ...(formData.vector && formData.vector.length > 0 ? { territorial_category: this.updateVectorLayers(formData.vector) } : {}),
      ...(formData.source && formData.source.length > 0 ? { source_list: formData.source } : {})
    };

    let vectorLayersQuery = {
      ...(formData.ti && formData.ti.length > 0 ? { ti_list: formData.ti.map(ti => ti.id) } : {}),
      ...(formData.class && formData.class.length > 0 ? { class_list: formData.class } : {}),
      ...(formData.source && formData.source.length > 0 ? { source_list: formData.source } : {}),
      ...(formData.buffer ? { buffer: formData.buffer } : {})
    };

    let alertSourcesQuery = {
      ...(formData.ti && formData.ti.length > 0 ? { ti_list: formData.ti.map(ti => ti.id) } : {}),
      ...(formData.class && formData.class.length > 0 ? { class_list: formData.class } : {}),
      ...(formData.vector && formData.vector.length > 0 ? { territorial_category: this.updateVectorLayers(formData.vector) } : {}),
    };

    await this.apiService.getGeomFeaturesTi(featuresTiQuery).toPromise()
      .then(
        (res) => {
          if (formData.ti && formData.ti.length) {
            formData.ti = formData.ti.filter(ti => res.map(ti => ti.id).includes(ti.id));
            this.formFilter.patchValue({ ti: formData.ti });
          }

          this.featuresTi = res;
        }
      )
      .catch(err => []);

    await this.apiService.getAlertClasses(alertClassesQuery).toPromise()
      .then(
        (res) => {
          if (formData.class && formData.class.length) {
            formData.class = formData.class.filter(className => res.some(classObject => classObject.type === className));
            this.formFilter.patchValue({ class: formData.class });
          }

          this.alertClasses = res;
        }
      )
      .catch(err => []);

    await this.apiService.getVectorLayers(vectorLayersQuery).toPromise()
      .then(
        (res) => {
          this.cities.length = 0;
          this.vectorLayers.length = 0;

          if ((formData.vector && formData.vector.length)) {
            const filteredVectors = this.formFilter.value.vector.filter(vector =>
              res.some(resVector =>
                resVector.id === vector.id && resVector.categoria === vector.categoria
              )
            );
            const filteredCities = this.formFilter.value.city.filter(vector =>
              res.some(resVector =>
                resVector.id === vector.id && resVector.categoria === vector.categoria
              )
            );

            formData.vector = [
              ...filteredVectors,
              ...filteredCities
            ];

            this.formFilter.patchValue({ vector: filteredVectors });
            this.formFilter.patchValue({ city: filteredCities });
          }

          res.sort((a, b) => {
            const aName = typeof a.id === 'string' ? '0' + a.name : a.name;
            const bName = typeof b.id === 'string' ? '0' + b.name : b.name;

            if (a.categoria === b.categoria) {
              return aName.localeCompare(bName);
            } else {
              return a.categoria.localeCompare(b.categoria);
            }
          });

          res = res.filter(item => typeof item.id !== 'string');
          res.forEach(vector => {
            if (vector.categoria === 'Municipio') {
              this.cities.push(vector);
            }
            else {
              this.vectorLayers.push(vector);
            }
          });
        }
      )
      .catch(err => []);

    await this.apiService.getAlertSources(alertSourcesQuery).toPromise()
      .then(
        (res) => {
          if (formData.source && formData.source.length) {
            formData.source = formData.source.filter(source => res.map(source => source.id).includes(source.id));
            this.formFilter.patchValue({ source: formData.source });
          }

          this.alertSources = res;
        }
      )
      .catch(err => []);

    await this.apiService.getAlertSatellite().toPromise()
      .then(
        res => this.alertSatellite = res
      )
      .catch(err => []);

    await this.apiService.getAlertSizes().toPromise()
      .then(
        res => this.alertSizes = res
      )
      .catch(err => []);

    // if (formData.ti || formData.ti.length > 0) {
    //   formData.vector = [];
    //   this.formFilter.patchValue({ vector: [] });
    // }

    onHeaderFilterChange.emit(formData);
  }

  async getPreviousAlertId() {

    let alertId = this.formFilter.value.alertId;
    const previousAlert = await this.apiService.getPreviousAlert(this.formFilter.value.alertId - 1 || 1000000).toPromise();

    if (previousAlert.message) {
      this.openDialog('AlertIdMessageComponent', 450, previousAlert.message)
    }

    alertId = previousAlert.id;
    this.formFilter.controls['alertId'].setValue(alertId);
  }

  async getNextAlertId() {

    let alertId = this.formFilter.value.alertId;
    const nextAlert = (await this.apiService.getNextAlert(this.formFilter.value.alertId + 1 || 0).toPromise());

    // if (nextAlert.message) {
    //   this.openDialog('AlertIdMessageComponent', 450, nextAlert.message)
    // }

    alertId = nextAlert.id;
    this.formFilter.controls['alertId'].setValue(alertId);
  }

  updateVectorLayers(vectorData) {
    if (vectorData && vectorData.length > 0) {
      vectorData = vectorData.reduce((acc, item) => {
        if (!acc[item.categoria]) {
          acc[item.categoria] = [];
        }
        acc[item.categoria].push(item.id);
        return acc;
      }, {});
    }
    return vectorData;
  }

  updateSizeRangeOptions(sizes) {
    const [min, max] = sizes;
    const selectedOptions: string[] = [];

    if (min === max) {
      return [];
    }
    else {
      if (min < 5 && max >= 0) {
        selectedOptions.push('0-5');
      }
      if (min < 10 && max >= 5) {
        selectedOptions.push('5-10');
      }
      if (max > 10) {
        selectedOptions.push('10+');
      }
    }

    if (selectedOptions.length === 3) {
      return [];
    }
    else {
      return selectedOptions;
    }
  }

  clearField(fieldName) {
    this.isClearing = true;
    if (fieldName !== 'dateEnd' && fieldName !== 'dateStart') {
      const patchedValue = {}
      patchedValue[fieldName] = this.initialFormValues[fieldName]
      this.formFilter.patchValue(patchedValue)
    }
    console.log('this form filter value', this.formFilter.value)
    this.isClearing = false;
  }

  clearFilterBtn(formData) {
    Object.keys(formData).forEach(key => {
      this.clearField(key)
    });

    formData.size = [0, this.ALERT_AREA_MAX];

    this.formFilter.setValue(formData);
    this.filterChange();
  }


  compareFilter(selected: any, option: any): boolean {
    return selected.id === option.id && selected.categoria === option.categoria;
  }

  panelExpansionClosed() {
    this.panelOpenState = false;
    onClickMarginTop.emit('CLOSE');
    localStorage.setItem('panelExpansion', 'CLOSE')
  }

  panelExpansionOpened() {
    this.panelOpenState = true;
    onClickMarginTop.emit('OPEN');
    localStorage.setItem('panelExpansion', 'OPEN')
  }

  toggleExpansion(open: boolean) {
    this.panelOpenState = open;
  }

  logout() {
    this.authService.logout();
    this.router.navigate(['/login']);
  }

  home() {
    this.router.navigate(['/home']);
  }

}
