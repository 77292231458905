import * as turf from '@turf/turf';

export class Alert {
    id: number;
    geom: any;
    source: string;
    date_insertion: string;
    date_after: string;
    date_before: string;
    image_id_after: string;
    image_id_before: string;
    geom_simplified: null;
    type: string;
    date_validation: string;s
    geom_original: any;
    type_validated: string;
    validation: boolean;
    embargoed: boolean;
    uc_name: boolean;
    ti_name: boolean;
    materialized: any[];
    area_ha: number;
    original_id:number;
    message?: string;

    getGeometryArea() {
        return new Number(this.area_ha).toFixed(2);
    }

    getSourceLabel() {
        return this.source == 'ALERT' ? 'SMDK' : this.source;
    }

    getDateInsertion() {
        if (this.date_insertion) {
            return new Date(this.date_insertion.replace('T00:00:00.000000Z', 'T10:00:00'));
        } else {
            return null;
        }
    }
    getDateAfter() {
        if (this.date_after) {
            return new Date(this.date_after.replace('T00:00:00.000000Z', 'T10:00:00'));
        } else {
            return null;
        }
    }

    getDateBefore() {
        if (this.date_before) {
            return new Date(this.date_before.replace('T00:00:00.000000Z', 'T10:00:00'));
        } else {
            return null;
        }
    }

    getDateValidation() {
        if (this.date_validation) {
            return new Date(this.date_validation.replace('T00:00:00.000000Z', 'T10:00:00'));
        } else {
            return null;
        }

    }
}
