import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { LoginDialogComponent } from './modules/users/login/login-dialog.component';
import { AuthEvents, AuthService } from './services/auth.service';
import { LoadingService } from './services/loading.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  constructor(
    private authService: AuthService,
    public dialog: MatDialog,
  ) { }
  ngOnInit() {

    AuthEvents.onUnauthorized.subscribe(() => {
      this.dialog.open(LoginDialogComponent, {
        width: `450px`
      });
    });
  }
  title = 'somai-covid';
}
