import * as dateFns from 'date-fns';
import pt from 'date-fns/locale/pt-BR';
import * as L from 'leaflet';
import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { CustomStepDefinition, LabelType, Options } from '@angular-slider/ngx-slider';
import { PLANET_API_KEY } from 'src/environments/environment';


@Component({
  selector: 'app-planet-image-slider',
  templateUrl: './planet-image-slider.component.html',
  styleUrls: ['./planet-image-slider.component.scss']
})
export class PlanetImageSliderComponent implements OnInit {

  @Input()
  map: L.Map;

  groupLayer = L.layerGroup();

  options: Options = null;

  value = 0;

  monthsInterval: Date[] = [];

  selectedMonth: Date;

  lastMonth: Date;

  constructor() { }

  ngOnInit(): void {
    this.selectedMonth = dateFns.subMonths(new Date(), 1);
    this.lastMonth = this.selectedMonth;

    this.monthsInterval = this.generateMonthsInterval(this.selectedMonth);

    this.options = {
      showTicksValues: true,
      showTicks: false,
      stepsArray: this.monthsInterval.map((date: Date, index): CustomStepDefinition => {
        return {
          value: index,
          legend: dateFns.format(date, 'yyyy-mm')
        };
      }),
      translate: (value: number, label: LabelType): string => {
        return dateFns.format(this.monthsInterval[value], 'MMM-yy', { locale: pt });
      }
    };
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['map']) {
      this.map.addLayer(this.groupLayer);
      setTimeout(() => {
        this.loadLayer();
      }, 100);
    }
  }

  onSliderChange(value: any) {
    this.value = value;
    this.loadLayer();
  }

  loadLayer() {

    this.groupLayer.clearLayers();

    const layers = this.groupLayer.getLayers();

    const layerDate = dateFns.format(this.monthsInterval[this.value], 'yyyy-MM');

    const url = `https://tiles0.planet.com/basemaps/v1/planet-tiles/` +
      `planet_medres_normalized_analytic_${layerDate}_mosaic/gmap/{z}/{x}/{y}.png?api_key=${PLANET_API_KEY}`;

    const layer = L.tileLayer(url);
    layer.setZIndex(10);

    this.groupLayer.addLayer(layer);

    if (layers.length > 0) {
      setTimeout(() => {
        this.groupLayer.removeLayer(layers[0]);
      }, 5000)
    }
  }

  previous() {
    this.lastMonth = dateFns.subMonths(this.lastMonth, 3);
    const referenceDate = new Date('2021-01-01');

    if (dateFns.isBefore(this.lastMonth, referenceDate)) {
      this.lastMonth = referenceDate
    }

    this.changeOptionsMonthsInterval(this.lastMonth);

    this.updateTooltipStyle('left');
  }

  next() {
    this.lastMonth = dateFns.addMonths(this.lastMonth, 3);
    const referenceDate = dateFns.subMonths(new Date(), 1);

    if (dateFns.isAfter(this.lastMonth, referenceDate)) {
      this.lastMonth = referenceDate
    }

    this.changeOptionsMonthsInterval(this.lastMonth);

    this.updateTooltipStyle('right');
  }

  changeOptionsMonthsInterval(lastMonth: Date){
    this.monthsInterval = this.generateMonthsInterval(lastMonth);
    this.options = {
      showTicksValues: true,
      showTicks: false,
      stepsArray: this.monthsInterval.map((date: Date, index): CustomStepDefinition => {
        return {
          value: index,
          legend: dateFns.format(date, 'yyyy-mm')
        };
    }),
    translate: (value: number, label: LabelType): string => {
      return dateFns.format(this.monthsInterval[value], 'MMM-yy', { locale: pt });
    }
    };
  }

  updateTooltipStyle(side: string) {
    const tooltipElements = document.querySelectorAll('.ngx-slider-inner-tooltip')
    if (tooltipElements) {
      tooltipElements.forEach((element) => {
        // Atualizar o estilo do elemento
        element.classList.add(`changed-${side}`);
      });
      setTimeout(() => {
        tooltipElements.forEach((element) => {
          element.classList.remove(`changed-${side}`);
        });
      }, 200);
    }
  }

  private generateMonthsInterval(month: Date): Date[] {
    const past = dateFns.subMonths(month, 11);
    const months = dateFns.eachMonthOfInterval({
      start: past,
      end: month
    });
    return months;
  }
}

