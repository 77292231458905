<div #mapComp style="height: 100%; width: 100%">
</div>
<app-statistic></app-statistic>
<app-point-inspector></app-point-inspector>
<app-btn-controll [map]="map">
  <app-search-localization [map]="map"></app-search-localization>
</app-btn-controll>
<app-sidenav-toggle-button></app-sidenav-toggle-button>
<app-planet-image-slider [map]="map">
</app-planet-image-slider>
