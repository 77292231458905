import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ApiService } from 'src/app/services/api.service';
import { AuthService } from 'src/app/services/auth.service';
import { FormControl, FormGroupDirective, NgForm, Validators } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';

export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const isSubmitted = form && form.submitted;
    return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
  }
}

type MailData = {
  email: string
}

@Component({
  selector: 'app-email',
  templateUrl: './email.component.html',
  styleUrls: ['./email.component.scss']
})
export class EmailComponent implements OnInit {
  mailData: MailData = {} as MailData;
  hasErrorUpdateEmail: boolean = false;
  constructor(
    private apiService: ApiService,
    private authService: AuthService,
    public dialogRef: MatDialogRef<EmailComponent>,
    @Inject(MAT_DIALOG_DATA) public data: []
  ) { }

  emailFormControl = new FormControl('', [Validators.required, Validators.email]);
  matcher = new MyErrorStateMatcher();

  ngOnInit(): void { }

  onNoClick(): void {
    this.dialogRef.close();
  }

  async updateMail() {
    const { user = {} }: any = this.authService.getAuthorization();
    try {
      await this.apiService.updateUser({ id: user.id, name: user.name, email: this.mailData.email }).toPromise()
      this.hasErrorUpdateEmail = false;
      this.dialogRef.close();
    } catch (e) {
      console.log(e, "Error")
      this.hasErrorUpdateEmail = true;
    }
  }
}
