import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UsersService } from 'src/app/services/users.service';
import { FormControl, FormGroupDirective, NgForm, FormBuilder, Validators, FormGroup } from '@angular/forms';
import { MyErrorStateMatcherChangePassword } from '../password/validate/changepassword.validate';
import { MatSnackBar } from '@angular/material/snack-bar';

type ChangePasswordData = {
  email:string,
  token: string,
  newPassword: string,
  confirmPassword: string
}

@Component({
  selector: 'app-recoverpassword',
  templateUrl: './recoverpassword.component.html',
  styleUrls: ['./recoverpassword.component.scss']
})
export class RecoverPasswordComponent implements OnInit {
  initialPage: boolean  = true;
  tokenPage: boolean    = false;
  newPassPage: boolean  = false;

  validEqualPasswordConfirm: boolean = false;
  errorRequest: boolean = false;
  errorToken: boolean   = false;
  errorEmail: boolean   = false;
  errorEmailMSG:string
  matcher               = new MyErrorStateMatcherChangePassword();
  changePasswordData = {
    email: '',
    token: '',
    newPassword: '',
    confirmPassword: '',
  }

  emailFC = new FormControl(this.changePasswordData.email, [Validators.required, Validators.email]);
  tokenFC = new FormControl(this.changePasswordData.token, [Validators.required]);

  changePasswordFB = this.fb.group({
    newPassword: [this.changePasswordData.newPassword, [Validators.required]],
    confirmPassword: [this.changePasswordData.confirmPassword, [Validators.required]],
  });


  constructor(
    private _snackBar: MatSnackBar,
    private fb: FormBuilder,
    private usersService: UsersService,
    public dialogRef: MatDialogRef<RecoverPasswordComponent>,
    @Inject(MAT_DIALOG_DATA) public data: []) { }

  ngOnInit(): void {
  }
  onNoClick(): void {
    this.dialogRef.close();
  }

  async emailForPassword() {
    try {
      this.errorEmail = false;
      await this.usersService.emailForPassword(this.changePasswordData).toPromise();
      this.initialPage  = false;
      this.tokenPage    = true;
    } catch (e) {
      this.errorEmail = true;
      this.errorEmailMSG =  e.error.msg
      this._snackBar.open("Não foi possível enviar um token para o seu email. Tente novamente.", 'Fechar',{duration: 3500});
      console.log(e, "Error")
    }
  }

  async tokenForPassword() {
    try {
      this.errorToken = false;
      await this.usersService.tokenForPassword(this.changePasswordData).toPromise();
      this.tokenPage    = false;
      this.newPassPage  = true;
    } catch (e) {
      this.errorToken   = true;
      this._snackBar.open("Ocorreu um problema. Tente novamente.", 'Fechar',{duration: 3500});
      console.log(e, "Error")
    }
  } 

  async recoveryPassword() {
    if (!this.isEqualConfirmPassword()) {
      this.validEqualPasswordConfirm = true
      return;
    }

    try {
      this.errorRequest = false;
      await this.usersService.recoveryPassword(this.changePasswordData).toPromise();
      this.dialogRef.close();
      this._snackBar.open("Alteração feita com sucesso", 'Fechar', {duration: 3000});
    } catch (e) {
      this.errorRequest = true;
      console.log(e, "Error")
    }
  }

  isEqualConfirmPassword(): boolean {
    return this.changePasswordData.newPassword === this.changePasswordData.confirmPassword
  }
}
