import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UsersService } from 'src/app/services/users.service';
import { FormControl, FormGroupDirective, NgForm, Validators, FormGroup } from '@angular/forms';
import { MyErrorStateMatcherChangePassword } from './validate/changepassword.validate';
import { MatSnackBar } from '@angular/material/snack-bar';

type ChangePasswordData = {
  old_password: string,
  password: string,
  confirm_password: string
}

@Component({
  selector: 'app-password',
  templateUrl: './password.component.html',
  styleUrls: ['./password.component.scss']
})
export class PasswordComponent implements OnInit {

  changePasswordFormGroup: FormGroup;
  errorRequest: boolean = false;
  constructor(
    private _snackBar: MatSnackBar,
    public dialogRef: MatDialogRef<PasswordComponent>,
    private usersService: UsersService,
    @Inject(MAT_DIALOG_DATA) public data: []) { }

  validEqualPasswordConfirm: boolean = false;
  matcher = new MyErrorStateMatcherChangePassword();
  changePasswordData: ChangePasswordData = {} as ChangePasswordData;
  ngOnInit(): void {
    this.changePasswordFormGroup = new FormGroup({
      old_password: new FormControl('', [Validators.required]),
      password: new FormControl('', [Validators.required]),
      confirm_password: new FormControl('', [Validators.required])
    })
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  async changePassword() {

    if (this.changePasswordFormGroup.invalid) {
      return;
    }
    if (!this.isEqualConfirmPassword()) {
      this.validEqualPasswordConfirm = true
      return;
    }

    try {
      this.errorRequest = false;
      await this.usersService.changePassword(this.changePasswordData).toPromise();
      this._snackBar.open("Operação realizada com sucesso.", 'Fechar',{duration: 3500});
      this.dialogRef.close();
    } catch (e) {
      this.errorRequest = true;
      console.log(e, "Error")
    }
  }

  isEqualConfirmPassword(): boolean {
    return this.changePasswordData.password === this.changePasswordData.confirm_password
  }
}
