import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { User } from 'src/app/services/entities/user.entity';
import { UsersService } from 'src/app/services/users.service';
import { onUserSaved } from '../add/add-events';
import { AddComponent } from '../add/add.component';
import { MatTableDataSource } from '@angular/material/table';
import {MatSort, Sort, MatSortModule} from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';

@Component({
  selector: 'app-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.scss']
})
export class ListComponent implements OnInit {
  @ViewChild(MatSort) sort!: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  modalComponents: {};

  users: User[];
  test:[];
  dataSource: MatTableDataSource<any>;

  displayedColumns: string[] = ['id', 'name', 'email','security_level', 'actions'];

  constructor(
    public dialog: MatDialog,
    private usersService: UsersService,
  ) { this.dataSource = new MatTableDataSource(); }

  ngOnInit(): void {
    this.modalComponents = {
      'AddComponent': AddComponent
    };
    this.list();

    onUserSaved.subscribe(() => {
      this.list();
    });
  }

  openDialog(component, width): void {
    this.dialog.open(this.modalComponents[component], {
      width: `${width}px`
    });
  }

  async list() {
    this.users = await this.usersService.list().toPromise();
    let test = [];
    for(var i=0; i<this.users.length; i++){
      test.push(Object.entries(this.users[i]).reduce(function(p, c) { p[c[0]] = c[1]; return p; }, {}))
    }
    // this.dataSource.data = test; 
    this.dataSource           = new MatTableDataSource(test);
    this.dataSource.sort      = this.sort;
    this.dataSource.paginator = this.paginator;
    this.paginator._intl.itemsPerPageLabel  = 'Elementos por página';
    this.paginator._intl.nextPageLabel      = 'Próxima página';
    this.paginator._intl.previousPageLabel  = 'Página anterior';
    this.paginator._intl.lastPageLabel      = 'Última página';
    this.paginator._intl.firstPageLabel     = 'Primeira página';
    this.paginator._intl.getRangeLabel = (page: number, pageSize: number, length: number) => {
      if (length === 0 || pageSize === 0) {
        return '0 de ' + length;
      }
      length = Math.max(length, 0);
      const startIndex = page * pageSize;
      // If the start index exceeds the list length, do not try and fix the end index to the end.
      const endIndex = startIndex < length ?
        Math.min(startIndex + pageSize, length) :
        startIndex + pageSize;
      return startIndex + 1 + ' - ' + endIndex + ' de ' + length;
    };
  }

  async edit(user: User) {
    this.dialog.open(AddComponent, {
      width: `450px`,
      data: {
        user: user
      }
    });
  }

  async remove(user: User) {
    const confirmResult = confirm('Deseja realmente remover o usuário?');
    if(confirmResult){
      await this.usersService.delete(user).toPromise();
      this.list();
    }
  }
}

