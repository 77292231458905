<section class="c-alert-id-message">
  <section class="c-alert-id-message__header">
    <div class="c-alert-id-message__header__text">
      <span>Alerta</span>
    </div>
    <span style="cursor: pointer; height: 23px;" (click)=onNoClick() mat-dialog-close>
      <svg width="23" height="23" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
        <line x1="6.36328" y1="16.3142" x2="16.3139" y2="6.36354" stroke="#868E96" stroke-width="2"
          stroke-linecap="round" />
        <line x1="16.3146" y1="16.2632" x2="6.36403" y2="6.31256" stroke="#868E96" stroke-width="2"
          stroke-linecap="round" />
      </svg>
    </span>
  </section>
  <section class="c-alert-id-message__content">
    <p>
      {{ data.message }}
    </p>
  </section>
</section>
