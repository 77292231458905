<section class="c-poup">
  <div class="c-poup__header">
    <span>{{territoryName}}</span>
    <a [routerLink]="[territoryCategory,territoryId]" routerLinkActive="router-link-active" >Ver mais</a>
  </div>
  <section class="c-poup__content">
    <div class="c-poup__content-item">
      <div class="content-item__label">
        <div class="bullet --green"></div>
        <span class="name">Vacinados</span>
      </div>
      <div class="content-item__number">
        
      </div>
    </div>
    <div class="c-poup__content-item">
      <div class="content-item__label">
        <div class="bullet --red"></div>
        <span class="name">Confirmados</span>
      </div>
      <div class="content-item__number">
       
      </div>
    </div>
    <div class="c-poup__content-item">
      <div class="content-item__label">
        <div class="bullet --black"></div>
        <span class="name">Óbitos</span>
      </div>
      <div class="content-item__number">
       
      </div>
    </div>
  </section>
</section>
