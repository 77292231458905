import { User } from "./entities/user.entity";

export class Authorization {

    /**
     * Token Access
     *
     * @type {string}
     * @memberof Authorization
     */
    token: string;

    /**
     * User data
     *
     * @type {*}
     * @memberof Authorization
     */
    user: User;
}
