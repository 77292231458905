<section class="c-share">
  <section class="c-share__header">
    <div class="c-share__header__text">
      <span>Compartilhar projeto</span>
    </div>
    <div class="c-share__header__close">
      <span (click)=onNoClick() mat-dialog-close>X</span>
    </div>
  </section>
  <section class="c-share__text">
    <span>O SMDK é fruto de uma parceria entre as organizações:</span>
    <section>
      <img src="assets/img/kaninde/logo.png" alt="">
      <img src="assets/img/solved_logo.png" alt="">
      <img src="assets/img/logo_moore.jpg" alt="">
      <img src="assets/img/logo_wwf.jpg" alt="">
    </section>
  </section>
  <section class="c-share__content">
    <share-buttons [theme]="'material-dark'"
    [include]="['linkedin','twitter','facebook','whatsapp']"
    [show]="9"
    [size]="3"
    [url]="''"
    [image] ="''"
    [autoSetMeta]="false"
>
</share-buttons>
  </section>
  <!-- <button mat-fab shareButton="facebook"
                  #fbBtn="shareButton"
                  [style.backgroundColor]="fbBtn?.color">
    <fa-icon *ngIf="fbBtn" [icon]="fbBtn.icon" size="lg"></fa-icon>
  </button>
  <button mat-fab shareButton="twitter"
                  #twtBtn="shareButton"
                  [style.backgroundColor]="twtBtn?.color">
    <fa-icon *ngIf="twtBtn" [icon]="twtBtn.icon" size="lg"></fa-icon>
  </button> -->


</section>
