import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ApiService } from './api.service';
import { LoadingService } from './loading.service';
import { AuthService } from './auth.service';
import { UsersService } from './users.service';
import { ServiceInterceptorsProviders } from './interceptors/services.interceptors';
@NgModule({
  declarations: [],
  imports: [
    CommonModule
  ],
  providers: [
    ApiService,
    AuthService,
    UsersService,
    LoadingService,
    ServiceInterceptorsProviders
  ]
})
export class ServicesModule { }
