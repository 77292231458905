<section class="c-login">
  <section class="c-login__header">
    <div class="c-login__header__text">
      <span>Autenticação necessária</span>
    </div>
    <div class="c-login__header__close">
      <span (click)=onNoClick() mat-dialog-close>X</span>
    </div>
  </section>


  <form #form="ngForm" (ngSubmit)="onSubmit()">
    <section class="c-login__content">
      <p>
        Informe seu email e senha
      </p>

      <mat-form-field appearance="outline">
        <mat-label>E-mail</mat-label>
        <input matInput placeholder="Informe seu e-mail" [(ngModel)]="loginData.email"
          [ngModelOptions]="{standalone: true}">
      </mat-form-field>

      <mat-form-field appearance="outline">
        <mat-label>Senha</mat-label>
        <input type="password" matInput placeholder="Inforne sua senha" [(ngModel)]="loginData.password"
          [ngModelOptions]="{standalone: true}">
      </mat-form-field>
      <mat-error *ngIf="showLoginError" style="margin-bottom: 5px;">Dados informados estão incorretos</mat-error>
    </section>
    <section class="c-login__btn">
      <button [disabled]="form.invalid" mat-raised-button color="primary">Entrar</button>
    </section>
  </form>
</section>