import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AboutComponent } from '../about/about.component';
import { ShareComponent } from '../share/share.component';
@Component({
  selector: 'app-header-user',
  templateUrl: './header-user.component.html',
  styleUrls: ['./header-user.component.scss']
})
export class HeaderUserComponent implements OnInit {
  modalComponents: {}
  constructor(
    public dialog: MatDialog

  ) {
    this.modalComponents = {
      'AboutComponent': AboutComponent,
      'ShareComponent': ShareComponent,
    }
  }

  async ngOnInit() {}

  openDialog(component, width): void {
    this.dialog.open(this.modalComponents[component], {
      width: `${width}px`
    });
  }

}
