<ng-container *ngIf="data && dataLength > 0">
  <div class="c-map-point-inspector" #inspectorPanel
  [ngClass]="(statusHeader === 'CLOSE' && !panelPointInspectorExpansion) ? 'c-map-animate-top' :
  (statusHeader === 'CLOSE' && panelPointInspectorExpansion)?'c-map-animate-right':
  (statusHeader === 'OPEN' && !panelPointInspectorExpansion)?'c-map-animate-bottom':
  (statusHeader === 'OPEN' && panelPointInspectorExpansion)?'c-map-animate-right-bottom':''">
    <div class="c-map-point-inspector__header">
      <span>Informações do ponto</span>
      <span style="cursor: pointer; height: 23px;" (click)="close()">
        <svg width="23" height="23" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
          <line x1="6.36328" y1="16.3142" x2="16.3139" y2="6.36354" stroke="#868E96" stroke-width="2"
            stroke-linecap="round" />
          <line x1="16.3146" y1="16.2632" x2="6.36403" y2="6.31256" stroke="#868E96" stroke-width="2"
            stroke-linecap="round" />
        </svg>
      </span>
    </div>
    <div class="c-map-point-inspector__content">
      <div class="c-map-point-inspector__content-chart">
        <span>Histórico Mapbiomas</span>
        <app-point-inspector-chart [data]="{ 'pointHistory': pointHistory, 'pointFireHistory': pointFireHistory }" #graph (graphSvg)="getSvg($event)"></app-point-inspector-chart>
      </div>
      <div class="c-map-point-inspector__content-info">
        <div class="c-map-point-inspector__content-info-item">
          <span class="item-category"><b>Coordenadas</b></span>
          <br>
          <span class="item-sub-category" (mouseenter)="showFullCordinates = true" (mouseleave)="showFullCordinates = false">
            ({{ showFullCordinates ? lat : lat.toFixed(3) + '...' }}, {{ showFullCordinates ? lng : lng.toFixed(3) + '...' }})
          </span>
        </div>
        <!-- <div class="c-map-point-inspector__content-item" > -->
        <ng-container *ngFor="let item of pointInfo">
          <div class="c-map-point-inspector__content-info-item" *ngIf="item.itens.length > 0">
            <span class="item-category"><b>{{ item.category }}</b></span>
            <br>
            <ng-container *ngFor="let subitem of item.itens">
              <span class="item-sub-category">
                {{ subitem.name }}
              </span>
              <br>
            </ng-container>
          </div>
        </ng-container>
      </div>
    </div>
  </div>
</ng-container>
