import { Component, ElementRef, Input, OnChanges, OnDestroy, OnInit, SimpleChanges, ViewChild, Output, EventEmitter } from '@angular/core';
import * as Highcharts from 'highcharts';
import { Options, SeriesOptionsType, SeriesScatterOptions, SeriesStatesHoverOptionsObject } from 'highcharts';

@Component({
  selector: 'app-point-inspector-chart',
  template: `
  <div #chart></div>
  `,
  //styleUrls: ['../charts.scss']
})
export class PointInspectorChartComponent implements OnInit, OnChanges, OnDestroy {
  @ViewChild('chart', { static: true })
  chartElem: ElementRef;

  @Input()
  name: string;

  @Input()
  data: any;

  chart: any;
  dataGraph: any[];
  typeUnit: string = 'und';
  toogleViewFullScream = true;
  mySvg: string;
  @Output()
  graphSvg = new EventEmitter<string>();

  constructor(
  ) { }

  ngOnInit(): void {

  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['data'] && 
        this.data?.pointHistory?.data && 
        this.data?.pointHistory?.legend &&
        this.data?.pointFireHistory?.data &&
        this.data?.pointFireHistory?.legend) {
      this.updateData();
    }
  }

  ngOnDestroy(): void {
    if (this.chart) {
      this.chart.destroy();
    }
  }


  async updateData() {
    if (!this.chartElem?.nativeElement) {
      return;
    }

    let years: number[] = [];
    let yearsFire: number[] = [];
    let series: SeriesScatterOptions[] = [];

    // Extract years from coverage data
    years = Object.keys(this.data.pointHistory.data)
      .map(key => parseInt(key.split('_')[1]))
      .sort();

    // Extract years from fire data
    yearsFire = Object.keys(this.data.pointFireHistory.data)
      .map(key => parseInt(key.split('_')[2]))
      .sort();

    // Get the legends
    const coverageLegend = this.data.pointHistory.legend.filter(l => l.linguage === 'pt-BR');
    const fireLegend = this.data.pointFireHistory.legend.filter(l => l.linguage === 'pt-BR');

    series = [{
      type: 'scatter',
      name: 'Cobertura',
      id: 'classification',
      marker: {
        symbol: 'circle'
      },
      data: years.map(year => {
        const classId = this.data.pointHistory.data[`classification_${year}`];
        const classificationClass = coverageLegend.find(c => c.classification === classId);
        return {
          x: year,
          y: 0,
          name: classificationClass.label,
          color: classificationClass.color
        };
      })
    },
    {
      type: 'scatter',
      name: 'Fogo',
      id: 'classification',
      marker: {
        symbol: 'circle'
      },
      data: yearsFire.map(year => {
        const classId = this.data.pointFireHistory.data[`burned_coverage_${year}`];
        if (classId !== 0) {
          const fireClass = fireLegend.find(c => c.classification === classId);
          return {
            x: year,
            y: 1,
            name: fireClass.label,
            color: fireClass.color
          };
        } else {
          return {x: year, y: null};
        }
      })
    }];

    const options: Options = {
      chart: {
        type: 'scatter',
        zoomType: 'xy',
        height: 200,
        width: 368,
      },
      exporting: {
        enabled: false
      },
      title: {
        text: null
      },
      subtitle: {
        text: undefined
      },
      xAxis: {
        title: {
          text: undefined
        },
        categories: years.map(String),
        labels: {
          format: '{value}',
          step: 4
        },
        startOnTick: false,
        endOnTick: false,
        showLastLabel: true,
      },
      yAxis: [{
        title: {
          text: undefined
        },
        categories: ["Cobertura", "Fogo"],
        tickInterval: 1,
      },
      {
        title: {
          text: undefined
        },
        tickInterval: 1,
      }],
      legend: {
        enabled: false
      },
      plotOptions: {
        scatter: {
          marker: {
            radius: 4,
            symbol: 'circle',
            states: {
              hover: {
                enabled: true,
                lineColor: 'rgb(100,100,100)'
              }
            }
          },
          states: {
            hover: {
              enabled: false
            } as SeriesStatesHoverOptionsObject
          }
        }
      },
      tooltip: {
        formatter: function(): string {
          const point = this.point as any;
          return `${point.x}: ${point.name}`;
        }
      },
      series: series
    };

    if (!this.chart) {
      this.chart = Highcharts.chart(this.chartElem.nativeElement, options);
    } else {
      this.chart.update(options);
    }
    this.getSVG();
  }

  downlaodCsv() {
    this.chart.downloadCSV()
  }

  getSVG() {
    try {
      this.mySvg = this.chart.getSVG()
      this.graphSvg.emit(this.mySvg);
    } catch (err) {
      // this.mySvg = err;
    }
  }
}

export const CLASSIFICATION_CLASSES = [
  { id: 1, label: 'Floresta', color: '#006400' },
  { id: 3, label: 'Formação Florestal', color: '#129912' },
  { id: 4, label: 'Formação Savânica', color: '#00ff00' },
  { id: 5, label: 'Mangue', color: '#687537' },
  { id: 49, label: 'Restinga Arborizada', color: '#6b9932' },
  { id: 10, label: 'Formação Natural não Florestal', color: '#bbfcac' },
  { id: 11, label: 'Campo Alagado e Área Pantanosa', color: '#45c2a5' },
  { id: 12, label: 'Formação Campestre', color: '#b8af4f' },
  { id: 32, label: 'Apicum', color: '#968c46' },
  { id: 29, label: 'Afloramento Rochoso', color: '#ff8C00' },
  { id: 50, label: 'Restinga Herbácea', color: '#66ffcc' },
  { id: 13, label: 'Outras Formações não Florestais', color: '#bdb76b' },
  { id: 14, label: 'Agropecuária', color: '#ffffb2' },
  { id: 15, label: 'Pastagem', color: '#ffd966' },
  { id: 18, label: 'Agricultura', color: '#e974ed' },
  { id: 19, label: 'Lavoura Temporária', color: '#d5a6bd' },
  { id: 39, label: 'Soja', color: '#c59ff4' },
  { id: 20, label: 'Cana', color: '#c27ba0' },
  { id: 40, label: 'Arroz (beta)', color: '#982c9e' },
  { id: 62, label: 'Algodão (beta)', color: '#660066' },
  { id: 41, label: 'Outras Lavouras Temporárias', color: '#e787f8' },
  { id: 36, label: 'Lavoura Perene', color: '#f3b4f1' },
  { id: 46, label: 'Café', color: '#cca0d4' },
  { id: 47, label: 'Citrus', color: '#d082de' },
  { id: 48, label: 'Outras Lavouras Perenes', color: '#cd49e4' },
  { id: 9, label: 'Silvicultura', color: '#935132' },
  { id: 21, label: 'Mosaico de Usos', color: '#fff3bf' },
  { id: 22, label: 'Área não Vegetada', color: '#ea9999' },
  { id: 23, label: 'Praia, Duna e Areal', color: '#dd7e6b' },
  { id: 24, label: 'Área Urbanizada', color: '#af2a2a' },
  { id: 30, label: 'Mineração', color: '#8a2be2' },
  { id: 25, label: 'Outras Áreas não Vegetadas', color: '#ff99ff' },
  { id: 26, label: "Corpo D'água", color: '#0000ff' },
  { id: 33, label: 'Rio, Lago e Oceano', color: '#0000ff' },
  { id: 31, label: 'Aquicultura', color: '#29eee4' },
  { id: 27, label: 'Não observado', color: '#D5D5E5' }
];
